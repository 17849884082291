<template>
  <section class="content-container">
    <SectionTabs :tabs="tab_component"/>
    <router-view/>
    <Spinner v-if="show_spinner"/>
  </section>
</template>

<!-- <template>
  <div class="benefit">
    <div class="benefit__sidebar">
      <SideBar />
    </div>
    <div class="benefit__container">
      <img src="@/assets/img/forma_1.svg" alt="" class="benefit__shape-one" />
      <img src="@/assets/img/forma_3.svg" alt="" class="benefit__shape-tw" />
      <img src="@/assets/img/forma_2.svg" alt="" class="benefit__shape-tr" />
      <div class="benefit__body">
        <div class="benefit__body--top">
          <SectionTitle :title="title" subtitle="" />
          <ProfileNav />
        </div>
        <div class="benefit__tabs">
          <SectionTabs :tabs="tab_component" />
        </div>
        <router-view />

        <footerSec></footerSec>
      </div>
    </div>
    <Spinner v-if="show_spinner" />
  </div>
</template> -->

<script>
  import Spinner from "@/components/Spinner.vue";
  //import { TIPO_BENEFICIO } from "@/apollo/queries";
  import SectionTabs from "@/components/Section/SectionTabs.vue";
  import { mapActions, mapState } from "vuex";

  export default {
    components: {
      Spinner,
      SectionTabs,
/*       SideBar,
      ProfileNav,
      SectionTitle,
      footerSec, */
    },
    data() {
      return {
        title: "Beneficios",
        // tab_component: [
        //   {
        //     name: "Dashboard",
        //     route: "benefit-Dashboard",
        //   },
        // ],
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        show_calendar: false,
        calendar_side: "",
        types: [],
        show_spinner: false,
      };
    },
    mounted() {
      setInterval(() => {
        this.checkIfUserIsLogged();
      }, 100);
      this.getType();
    },
    computed: {
      ...mapState("benefitModule", ["tab_component"]),
      attributes() {
        return {
          highlight: true,
          color: "#009CDE",
          dates: new Date(),
        };
      },
    },
    methods: {
      ...mapActions("benefitModule", ["getTipoDeSolicitudes"]),

      goTo(route) {
        var current_route = this.$route.name;
        //console.log("rev: ",current_route);
        if (route == current_route) {
          return;
        } else {
          this.$router.push({ name: route });
        }
      },
      activeTab(tab) {
        //console.log("rev: ",current_route);

        var current_route = this.$route.name;
        if (tab == current_route) {
          return "active";
        }
      },
      async getType() {
        this.show_spinner = true;
        let data = {
          id_empresa: this.id_empresa,
        }
        await this.getTipoDeSolicitudes(data).then(resp=>{
          if(resp!=null){
            console.log("mensaje de exito");
          }else{
            console.log("mensaje de falla");
          }
          this.show_spinner = false;
        });
        
      },
    },
  };
</script>
